import React, {useEffect, useState} from "react";
import "./individualDisplay.css"
import {API_KEY, CHANGE_DELAY, API_PROMO_URL} from "../constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Service from "./Service";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Categories from "./promotions/Categories";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function Promotions(props) {
    const {displayData} = props;
    const b = displayData.branchID;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState(null);
    const classes = useStyles();
    let categoryID = 0;

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = CHANGE_DELAY;
                return Math.min(oldProgress + diff, 100);
            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [])

    useEffect(() => {
        let params = new URLSearchParams({
            b: b,
            api: API_KEY
        });
        let URL = API_PROMO_URL + params;
        let ignore = false;
        const getPromoAPI = async () => {
            try {
                setIsLoaded(false);
                let response = await fetch(URL);
                setStatus(response.status);
                if (response.status !== 403 && response.status !== 200) {
                    setTimeout(() => {
                        return getPromoAPI()
                    }, 2000)
                }
                response = await response.json();
                if (!ignore) setData(response);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoaded(true);
            }
        };
        getPromoAPI();
        return () => {
            ignore = true;
        };

    }, [b])

    if (error && (status === 403)) {
        return (<Service error={error}/>);
    } else if (!isLoaded) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>;
        //} else if (data.id.length !== 1) {
        //    return <div>No Equipment in System...</div>;
    } else {
        // ADD Color of Linear Progress Bar
        let color = 'primary';
        if (b === '3672') {
            color = 'secondary';
        }
        for (let i = 0; i < data.categories.length; i++) {
            if (displayData.template === 'Promotions' && data.categories[i].titel === 'Aktionen') {
                categoryID = data.categories[i].id;
            } else if (displayData.template === 'Equipment' && data.categories[i].titel === 'Equipment') {
                categoryID = data.categories[i].id;
            }
        }
        return (
            <Container maxWidth="lg" className={classes.container}>
                <Grid container direction='column' spacing={2}>
                    <Grid container item direction='column' spacing={2}>
                        <Grid item>
                            <LinearProgress variant="determinate" color={color} value={progress}/>
                        </Grid>
                        <Grid item>
                            <Categories id={categoryID}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}
//
