import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {URL_QR} from "../../constants";

const useStyles = makeStyles((theme) => ({
        container: {
            padding: theme.spacing(1),
        },
        img: {
            border: 0,
        }
    }
))

export default function QRCode(props) {
    const classes = useStyles();
    const {vehicleData, url} = props;

    const paramsVH = new URLSearchParams({
        vi: vehicleData.VehicleId,
    });
    const params = new URLSearchParams({
        data: url + paramsVH,
        size: "100x100"
    });
    return (
        <Container maxWidth="lg" className={classes.container}>
            <img src={URL_QR + params}
                 className={classes.img}
                 alt={''}/>
        </Container>
    );
}