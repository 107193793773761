import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import {create} from 'jss';
import jssPluginVendorPrefixer from 'jss-plugin-vendor-prefixer';
import {StylesProvider, jssPreset} from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import Display from './Display';
import reportWebVitals from './reportWebVitals';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faClock,
    faCar,
    faCalendarAlt,
    faGasPump,
    faRoad,
    faTachometerAlt,
    faCogs
} from '@fortawesome/free-solid-svg-icons'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Service from "./components/Service";

const jss = create({
    plugins: [...jssPreset().plugins, jssPluginVendorPrefixer()],
});

library.add(faClock, faCar, faCalendarAlt, faGasPump, faRoad, faTachometerAlt, faCogs)
ReactDOM.render(
    <React.StrictMode>
        <StylesProvider jss={jss}>
            <Router>
                <Switch>
                    <Route path="/d=:d&c=:c">
                        <Display/>
                    </Route>
                    <Route exact path="/">
                        <Service/>
                    </Route>
                </Switch>
            </Router>
        </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*        <Router>
            <Switch>
                <Route path="/d=:d&k=:k">
                    <Display/>
                </Route>
                <Route exact path="/">
                    <Display/>
                </Route>
            </Switch>
        </Router>

 */
