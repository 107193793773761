import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {CARZILLA_URL, CHANGE_DELAY} from "../constants";
import Pictures from './vehicle/pictures'
import EssentialData from './vehicle/essential_data'
import SecondaryData from "./vehicle/secondary_data";
import Address from "./vehicle/address";
import Emissions from "./vehicle/emissions";
import CallToAction from "./vehicle/callToAction";
import Disclaimer from "./vehicle/disclaimer";
import QRCode from "./vehicle/qrCode";
import CircularProgress from '@material-ui/core/CircularProgress';
import Service from "./Service";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    paper: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function IndividualDisplay(props) {
    const {displayData} = props;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [progress, setProgress] = useState(0);
    const [refresh, setRefresh] = useState(true);
    const [status, setStatus] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    setRefresh(!refresh);
                    return 0;
                }
                const diff = CHANGE_DELAY;
                return Math.min(oldProgress + diff, 100);
            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [refresh])
    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Randomize: true,
                SuppressRawVehicles: false,
                page: 1,
                DisableCache: true,
                searchId: displayData.searchID,
                searchParams: [{Branches: [displayData.branchID]}]
            }),
        };
        let ignore = false;
        const getCarzillaAPI = async () => {
            try {
                setIsLoaded(false);
                let response = await fetch(CARZILLA_URL, requestOptions);
                setStatus(response.status);
                if (response.status !== 403 && response.status !== 200) {
                    setTimeout(() => {
                        return getCarzillaAPI()
                    }, 2000)
                }
                response = await response.json();
                if (!ignore) setItems(response);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoaded(true);
            }
        }
        getCarzillaAPI();
        return () => {
            ignore = true;
        };
    }, [displayData.searchID, displayData.branchID, refresh])


    if (error && (status === 403)) {
        console.log(error, status)
        return (<Service error={error}/>);
    } else if (!isLoaded) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>;
    } else if (items.vehicles.length !== 1) {
        return <div>No Vehicles in System...</div>;
    } else {
        const vehicleData = items.vehicles[0];
        // ADD Color of Linear Progress Bar
        let color = 'primary';
        if (vehicleData.Branch.BranchId === 3672) {
            color = 'secondary';
        }
        return (
            <div>
                <Container className={classes.container}>
                    <Grid container direction='column' spacing={1}>
                        <Grid container item direction='column' spacing={1}>
                            <Grid item>
                                <LinearProgress variant="determinate" color={color} value={progress}/>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.paper} variant="outlined">
                                    <h3 className={"pLeft"}>{vehicleData.Make.Name} {vehicleData.Version} {vehicleData.VersionAdditional}</h3>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.paper} variant="outlined">
                                    <Pictures vehicleData={vehicleData}/>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container item direction='row' spacing={0} justify='space-between'>
                            <Grid container item direction='column' xs={6} justify='space-between' spacing={2}>
                                <Grid item>
                                    <SecondaryData vehicleData={vehicleData}/>
                                </Grid>
                                <Grid item>
                                    <Emissions vehicleData={vehicleData}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction='column' xs={6} justify='space-between' spacing={1}>
                                <Grid item>
                                    <EssentialData vehicleData={vehicleData}/>
                                </Grid>
                                <Grid item>
                                    <Address vehicleData={vehicleData}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction='row' spacing={1}>
                            <Grid item xs={12}>
                                <CallToAction vehicleData={vehicleData}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <QRCode vehicleData={vehicleData} url={displayData.url}/>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Disclaimer/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}
