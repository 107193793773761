import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PROMO_PICTURE_DELAY_MS} from "../../constants";

const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
        img: {
            border: 0,
            width: '95vw'
        }
    }
))


export default function ImageArray(props) {
    const {images} = props;
    const classes = useStyles();
    const idArray = [];
    const numberofIMGs = images.length;
    const [position, setPosition] = useState(Math.floor(Math.random() * numberofIMGs));
    const startSmall = (position + 1) % numberofIMGs;
    for (let i = startSmall; i < startSmall + images.length; i++) {
        idArray.push(i % numberofIMGs)
    }
    useEffect(() => {
        let timer1 = setTimeout(() => {
            setPosition((position + 1) % numberofIMGs);
        }, PROMO_PICTURE_DELAY_MS);

        return () => {
            clearTimeout(timer1);
        }
    }, [position, numberofIMGs])
    return (
        <div>
            {idArray.map((id, index) => {
                let img = images[id];
                return <img src={img.url} alt={img.alt} key={index}
                            className={classes.img}/>
            })}
        </div>
    );
}
