import React, {useEffect, useState} from "react";
import IndividualDisplay from "./IndividualDisplay";
import "./individualDisplay.css"
import Promotions from "./Promotions";
import {CHANGE_COMPONENT_DELAY_MS} from "../constants";

export default function Multiview(props) {
    const {displayData} = props;
    let [view, setView] = useState(0);
    let viewIDs = [];
    for (let i = 0; i < displayData.multiviewID.length; i++) {
        viewIDs.push(+displayData.multiviewID.charAt(i));
    }
    useEffect(
        () => {
            let timer1 = setInterval(() => {
                setView(view++);
            }, CHANGE_COMPONENT_DELAY_MS);

            return () => {
                clearInterval(timer1)
            }
        },
        [view]
    )
    if (view >= viewIDs.length) setView(0);
    if (viewIDs[view] === 1) { // IndividualDisplay
        displayData.template = 'Individual';
        return (
            <div className="App">
                <IndividualDisplay displayData={displayData}/>
            </div>
        )
    } else if (viewIDs[view] === 2) { // Equipment
        displayData.template = 'Equipment';
        return (
            <div className="App">
                <Promotions displayData={displayData}/>
            </div>
        )
    } else if (viewIDs[view] === 3) { // Promotions
        displayData.template = 'Promotions';
        return (
            <div className="App">
                <Promotions displayData={displayData}/>
            </div>
        )
    } else {
        return (
            <div>
                <p>Change Values in Database</p>
            </div>
        )
    }
}
