import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    longCell: {
        minWidth: '145px',
    }
}));

export default function Secondary_data(props) {
    const {vehicleData} = props;
    const classes = useStyles();
    return (
        <TableContainer component={Paper} variant="outlined">
            <Table aria-label="spanning table">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3} className={classes.longCell}>
                            <b>Hubraum</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.EngineSize.FormattedValue || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <b>Anzahl Gänge</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.Gears || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <b>Sitzplätze</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.Seats || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <b>Anzahl Türen</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.DoorsCombined || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <b>Innenfarbe</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.ColorInterieur.AdditionalValue || "-"} ({vehicleData.ColorInterieur.Value || "-"})
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <b>Polsterung</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.Upholstery.Value || "-"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <b>Außenfarbe</b>
                        </TableCell>
                        <TableCell>
                            {vehicleData.Color.AdditionalValue || "-"} ({vehicleData.Color.Value || "-"})
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}