import React, {useEffect, useState} from "react";
import 'typeface-open-sans';
import IndividualDisplay from "./components/IndividualDisplay";
import "./components/individualDisplay.css"
import {useParams} from "react-router-dom";
import {API_KEY, API_DISPLAY_URL} from "./constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Service from "./components/Service";
import Promotions from "./components/Promotions";
import Multiview from "./components/Multiview";
import MultiDisplay from "./components/MultiDisplay";

export default function Display() {
    let {d, c} = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        let params = new URLSearchParams({
            d: d,
            c: c,
            api: API_KEY
        });
        let URL = API_DISPLAY_URL + params;
        let ignore = false;
        const getDataAPI = async () => {
            try {
                setIsLoaded(false);
                let response = await fetch(URL);
                if (response.status !== 403 && response.status !== 200) {
                    setTimeout(() => {
                        return getDataAPI()
                    }, 2000)
                }
                console.log(response.status);
                response = await response.json();
                if (!ignore) setData(response);
            } catch (err) {
                console.log(err)
                setError(err);
            } finally {
                setIsLoaded(true);
            }
        };
        getDataAPI();
        return () => {
            ignore = true;
        };
    }, [c, d])

    if (error) {
        return (<Service error={error}/>);
    } else if (!isLoaded) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>;
    } else if (data.template === 'Individual') {
        return (
            <div className="App">
                <IndividualDisplay displayData={data}/>
            </div>
        );
    } else if (data.template === 'Promotions' || data.template === 'Equipment') {
        return (
            <div className="App">
                <Promotions displayData={data}/>
            </div>
        );
    } else if (data.template === 'Multiview') {
        return (
            <div className="App">
                <Multiview displayData={data}/>
            </div>
        );
    } else if (data.template === 'MultiDisplay') {
        return (
            <div className="App">
                <MultiDisplay displayData={data}/>
            </div>
        );
    } else {
        return (
            <div className="App">
                <p>Not Set!</p>
            </div>
        );
    }

}
