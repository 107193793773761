import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {CARZILLA_URL, CHANGE_DELAY} from "../constants";
import CircularProgress from '@material-ui/core/CircularProgress';
import Service from "./Service";
import LinearProgress from "@material-ui/core/LinearProgress";
import MultiDisplayCar from "./vehicle/MultiDisplayCar";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function MultiDisplay(props) {
    const {displayData} = props;
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [progress, setProgress] = useState(0);
    const [refresh, setRefresh] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    setRefresh(!refresh);
                    return 0;
                }
                const diff = CHANGE_DELAY;
                return Math.min(oldProgress + diff, 100);
            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [refresh])

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Randomize: true,
                SuppressRawVehicles: false,
                page: 1,
                vehiclePerPage: 4,
                DisableCache: true,
                searchId: displayData.searchID,
                searchParams: [{Branches: [displayData.branchID]}]
            }),
        };
        let ignore = false;
        const getCarzillaAPI = async () => {
            try {
                setIsLoaded(false);
                let response = await fetch(CARZILLA_URL, requestOptions);
                response = await response.json();
                if (!ignore) setItems(response);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoaded(true);
            }
        }
        getCarzillaAPI();
        return () => {
            ignore = true;
        };
    }, [displayData.searchID, displayData.branchID, refresh])


    if (error) {
        return (<Service error={error}/>);
    } else if (!isLoaded) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>;
    } else if (items.vehicles.length >= 5) {
        return <div>No Vehicles in System...</div>;
    } else {
        // ADD Color of Linear Progress Bar
        let color = 'primary';
        if (items.vehicles[0].Branch.BranchId === 3672) {
            color = 'secondary';
        }
        return (
            <div>
                <Container maxWidth="lg" className={classes.container}>

                    <Grid container direction='row' spacing={2}>
                        <Grid xs={12} item>
                            <LinearProgress variant="determinate" color={color} value={progress}/>
                        </Grid>
                        <Grid xs={6} item>
                            <MultiDisplayCar vehicleData={items.vehicles[0]}/>
                        </Grid>
                        <Grid xs={6} item>
                            <MultiDisplayCar vehicleData={items.vehicles[1]}/>
                        </Grid>
                        <Grid xs={6} item>
                            <MultiDisplayCar vehicleData={items.vehicles[2]}/>
                        </Grid>
                        <Grid xs={6} item>
                            <MultiDisplayCar vehicleData={items.vehicles[3]}/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}
