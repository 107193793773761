import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import logo from '../constants/LogoHighRes.png';
import Grid from '@material-ui/core/Grid';
import "./individualDisplay.css";

export default function Service(props) {
    const {error} = props;
    console.log(error);
    let Heading = "Wartungsarbeiten";
    let text1 = "Dieser Display wird gerade gewartet,"
    let text2 = "haben Sie einen Moment Geduld.";
    if (error) {
        Heading = "Bitte gültige Kundendaten eingeben!";
        text1 = "Falls Sie keine Zugangsdaten habe, wenden";
        text2 = "Sie sich bitte an mail@kk-hosting.de"
    }
    return (<div
            style={{background: 'linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(233,233,233,1) 100%, rgba(0,212,255,1) 100%)'}}>
            <LinearProgress/>
            <Grid
                container
                spacing={0}
                padding={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{minHeight: '100vh'}}
            >
                <Grid item xs={12} align="center">
                    <img src={logo} alt={'AH Schmidt'} width={300}/>
                </Grid>
                <Grid item xs={12} align="center">
                </Grid>
                <Grid item xs={12} align="center">
                    <h1 className="App">{Heading}</h1>
                </Grid>
                <Grid item xs={12} align="center">
                    <h2 className="App">{text1}<br/>{text2}</h2>
                    <h3 className="App">© Copyright <br/>KK Hosting {new Date().getFullYear()}</h3>
                </Grid>
            </Grid>
        </div>
    );
}