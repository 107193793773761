import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import {URL_IMG} from "../../../constants";

const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
        img: {
            border: 0,
            width: '12vw',
            maxWidth: 145
        }
    }
))

export default function SmallPicture(props) {
    const classes = useStyles();
    const {vehicleData, position} = props;
    const params = new URLSearchParams({
        vid: vehicleData.VehicleId,
        bid: vehicleData.Branch.BranchId,
        format: "s",
        ino: position + 1
    });
    return (
        <Grid item xs={12} md={2}>
            <img src={URL_IMG + params}
                 className={classes.img}
                 alt={''}/>
        </Grid>
    );
}
