import React from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import EssentialData from "./essential_data";
import CallToAction from "./callToAction";
import BigPicture from "./picture/bigPicture"
import '../MultiDisplay.css'


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    paperIn: {
        paddingLeft: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        minHeight: 80
    },
    paperPic: {
        paddingLeft: theme.spacing(0),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        minHeight: 80
    },
}));

export default function MultiDisplayCar(props) {
    const {vehicleData} = props;
    const classes = useStyles();
    return (
        <Grid container direction='column' spacing={1}>
            <Paper className={classes.paperIn} variant="outlined">
                <h3 className={"h3"}>{vehicleData.Make.Name} {vehicleData.Version}</h3>
                <h5 className={"h5"}>{vehicleData.VersionAdditional}</h5>
            </Paper>
            <Paper className={classes.paperPic} variant="outlined">
                <BigPicture vehicleData={vehicleData} position={0}/>
            </Paper>
            <EssentialData vehicleData={vehicleData} multiView={true}/>
            <CallToAction vehicleData={vehicleData}/>
        </Grid>
    );

}
