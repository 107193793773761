import React, {useEffect, useState} from "react";
import {API_KEY, API_PROMO_IMG_URL} from "../../constants";
import Service from "../Service";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageArray from "./ImageArray";

export default function Categories(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const {id} = props;

    useEffect(() => {
        let params = new URLSearchParams({
            id: id,
            api: API_KEY
        });
        let URL = API_PROMO_IMG_URL + params;
        let ignore = false;
        const getPromoAPI = async () => {
            try {
                setIsLoaded(false);
                let response = await fetch(URL);
                response = await response.json();
                if (!ignore) setData(response);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoaded(true);
            }
        };
        getPromoAPI();
        return () => {
            ignore = true;
        };

    }, [id])

    if (error) {
        return (<Service error={error}/>);
    } else if (!isLoaded) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>;
    } else if (data.images.length < 1) {
        return <div>No Promotion Pictures in System...</div>;
    } else {
        return (
            <div>
                <ImageArray images={data.images}/>
            </div>
        );
    }
}
