import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import BigPicture from "./picture/bigPicture";
import SmallPicture from "./picture/smallPicture";
import {PICTURE_DELAY_MS} from "../../constants";

const useStyles = makeStyles((theme) => ({
            container: {
                paddingTop: theme.spacing(0),
                paddingBottom: theme.spacing(0),
                overflow: "hidden"
            }
        }
    ))
;

export default function Pictures(props) {
    const [bigPicturePosition, setBigPicturePosition] = useState(0);
    const classes = useStyles();
    const {vehicleData} = props;
    const numberOfIMGs = vehicleData.CountImages;
    const startSmall = (bigPicturePosition + 1) % numberOfIMGs;
    const smallIds = [];
    if (numberOfIMGs >= 6) {
        for (let i = startSmall; i < startSmall + 6; i++) {
            smallIds.push(i % numberOfIMGs)
        }
    } else {
        for (let i = startSmall; i < startSmall + numberOfIMGs; i++) {
            smallIds.push(i % numberOfIMGs)
        }
    }

    useEffect(() => {
        let timer1 = setTimeout(() => {
            setBigPicturePosition((bigPicturePosition + 1) % numberOfIMGs);
        }, PICTURE_DELAY_MS);
        return () => {
            clearTimeout(timer1)
        }
    }, [bigPicturePosition, numberOfIMGs])
    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={1}>
                <BigPicture vehicleData={vehicleData} position={bigPicturePosition}/>
                {smallIds.map(id => <SmallPicture vehicleData={vehicleData} position={id} key={id}/>)}
            </Grid>
        </Container>
    );
}
