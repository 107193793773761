export const PICTURE_DELAY_MS = 1000 * 5;
export const PROMO_PICTURE_DELAY_MS = 1000 * 8;
export const CHANGE_DELAY = 3;
export const CHANGE_COMPONENT_DELAY_MS = 1000 * 30;
export const URL_IMG = "https://display.kk-hosting.de/api/readImg.php?"
export const URL_QR = "https://api.qrserver.com/v1/create-qr-code/?"
export const API_KEY = "jBsGMF5eqtgea8pqGrUM23UALjuzrSYn"
export const API_DISPLAY_URL = process.env.NODE_ENV === 'production' ? 'https://display.kk-hosting.de/api/readData.php?' : '/api/data/readData.php?';
export const CARZILLA_URL = process.env.NODE_ENV === 'production' ? 'https://display.kk-hosting.de/api/proxy.php' : '/api/carzilla';
export const API_PROMO_URL = process.env.NODE_ENV === 'production' ? 'https://display.kk-hosting.de/api/readCategories.php?' : '/api/promo/readCategories.php?';
export const API_PROMO_IMG_URL = process.env.NODE_ENV === 'production' ? 'https://display.kk-hosting.de/api/readPromoImg.php?' : '/api/promoImg/readPromoImg.php?';
